import { ApplicationInsights } from '@microsoft/applicationinsights-web';


let ai = null;

if (process.env.BROWSER) {
  if (__DEV__) {
    ai = {
      trackException: ({ exception, properties }) => {
        console.error(`Track Exception: ${exception}\n`, properties);
        console.error(exception.stack);
      },
      trackEvent     : ({ name, properties }) => console.info(`Track Event: ${name}`, properties),
      trackPageView  : (properties) => console.info('Track PageView', properties),
      startTrackEvent: (eventName) => console.info(`Start Track Event: ${eventName}`),
      stopTrackEvent : (eventName, properties) => console.info(`Stop Track Event: ${eventName}`, properties),
    };
  } else {
    const connectionString = window.App.apps.appInsights;
    ai = new ApplicationInsights({
      config: {
        connectionString,
        enableAutoRouteTracking: true,
      },
    });
    ai.loadAppInsights();
  }
}


export default {
  trackEvent     : (eventName, properties) => ai && ai.trackEvent({ name: eventName, properties }),
  trackPageView  : (properties) => ai && ai.trackPageView(properties),
  trackException : (exception, properties) => ai && ai.trackException({ exception, properties }),
  startTrackEvent: (eventName) => ai && ai.startTrackEvent(eventName),
  stopTrackEvent : (eventName, properties) => ai && ai.stopTrackEvent(eventName, properties),
};
